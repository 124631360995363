import React from 'react';
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { Amount } from '../../shared/components/styled/amount';
import { NumericFormat } from 'react-number-format';
import { keyframes } from '@mui/system';
import CurrencyIcon from '@shared/components/currencyIcon/CurrencyIcon';
const spin = keyframes `
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(360deg);
  }
`;
var ACCOUNTS_TYPES;
(function (ACCOUNTS_TYPES) {
    ACCOUNTS_TYPES["virtual_wallet"] = "Wallet";
    ACCOUNTS_TYPES["trading_wallet"] = "Trading wallet";
    ACCOUNTS_TYPES["settlement_line"] = "Settlemnet line";
})(ACCOUNTS_TYPES || (ACCOUNTS_TYPES = {}));
const convertAmountValue = (val, precision) => {
    const number = new Decimal(val).toFixed(precision);
    const trimmed = parseFloat(number).toString();
    return trimmed;
};
const BalanceListItem = (props) => {
    const theme = useTheme();
    const { item, precision, pinned, loading } = props;
    return (React.createElement(Box, { sx: { height: pinned ? '60px' : 'auto', flex: pinned ? '1 0 auto' : 'none' } }, pinned ? (React.createElement(Box, { sx: {
            height: '100%',
        } },
        React.createElement(Box, { pr: 2, gap: 4, sx: { height: '100%', display: 'flex' } },
            React.createElement(Box, { py: 1, sx: { display: 'flex', justifyContent: 'start', alignItems: 'center' } },
                React.createElement(Box, { gap: 1, sx: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                    } },
                    item.code,
                    React.createElement(Box, { sx: { animation: loading ? `${spin} .5s infinite ease` : 'none' } },
                        React.createElement(CurrencyIcon, { code: item.code })))),
            React.createElement(Box, { textAlign: 'right', sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: 150 } },
                React.createElement(Amount, { size: '14', textColor: theme.palette.text.secondary },
                    React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue(item.available, precision), thousandSeparator: "'" })),
                React.createElement(Typography, { variant: 'subtitle2' }, "Available")),
            React.createElement(Box, { textAlign: 'right', sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: 150 } },
                React.createElement(Amount, { size: '14', textColor: theme.palette.text.secondary },
                    React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue(item.locked, precision), thousandSeparator: "'" })),
                React.createElement(Typography, { variant: 'subtitle2' }, "Locked")),
            React.createElement(Box, { textAlign: 'right', sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: 150 } },
                React.createElement(Amount, { size: '14', bold: true },
                    React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue(item.total, precision), thousandSeparator: "'" })),
                React.createElement(Typography, { variant: 'subtitle2' }, "Total"))))) : (React.createElement(Box, { sx: {
            height: '100%',
            backgroundColor: theme.palette.background.paper,
        } },
        React.createElement(Grid, { container: true, pr: pinned ? 2 : '', sx: { height: '100%' } },
            React.createElement(Grid, { item: true, xs: pinned ? 1 : 3, py: 1, sx: { display: 'flex', justifyContent: pinned ? 'start' : 'end', alignItems: 'center' } },
                React.createElement(Box, { gap: 1, sx: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                    } },
                    item.code,
                    React.createElement(CurrencyIcon, { code: item.code }))),
            React.createElement(Grid, { item: true, xs: pinned ? 2 : 3, gap: pinned ? 1 : 0, textAlign: 'right', sx: { display: 'flex', justifyContent: pinned ? 'start' : 'end', alignItems: 'center' } },
                pinned ? React.createElement(Typography, { variant: 'subtitle1' }, "Available") : null,
                React.createElement(Amount, { size: '14', textColor: theme.palette.text.secondary },
                    React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue(item.available, precision), thousandSeparator: "'" }))),
            React.createElement(Grid, { item: true, xs: pinned ? 2 : 3, gap: pinned ? 1 : 0, textAlign: 'right', sx: { display: 'flex', justifyContent: pinned ? 'start' : 'end', alignItems: 'center' } },
                pinned ? React.createElement(Typography, { variant: 'subtitle1' }, "Locked") : null,
                React.createElement(Amount, { size: '14', textColor: theme.palette.text.secondary },
                    React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue(item.locked, precision), thousandSeparator: "'" }))),
            React.createElement(Grid, { item: true, xs: pinned ? 2 : 3, pr: 1, gap: pinned ? 1 : 0, textAlign: 'right', sx: { display: 'flex', justifyContent: pinned ? 'start' : 'end', alignItems: 'center' } },
                pinned ? React.createElement(Typography, { variant: 'subtitle1' }, "Total") : null,
                React.createElement(Amount, { size: '14', bold: true },
                    React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue(item.total, precision), thousandSeparator: "'" }))),
            !pinned ? (React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Divider, { orientation: 'horizontal' }))) : null,
            !pinned
                ? item.accounts && item.accounts.length > 1
                    ? item.accounts.map((acc) => acc.total !== '0' ? (React.createElement(React.Fragment, { key: acc.code },
                        React.createElement(Grid, { item: true, xs: 3, textAlign: 'right', py: 1 }, ACCOUNTS_TYPES[acc.type]),
                        React.createElement(Grid, { item: true, xs: 3, textAlign: 'right', py: 1 },
                            React.createElement(Amount, { size: '12', textColor: theme.palette.text.secondary },
                                React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue(acc.available, precision), thousandSeparator: "'" }))),
                        React.createElement(Grid, { item: true, xs: 3, textAlign: 'right', py: 1 },
                            React.createElement(Amount, { size: '12', textColor: theme.palette.text.disabled },
                                React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue(acc.locked, precision), thousandSeparator: "'" }))),
                        React.createElement(Grid, { item: true, xs: 3, textAlign: 'right', pr: 1, py: 1 },
                            React.createElement(Amount, { size: '12', bold: true },
                                React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue(acc.total, precision), thousandSeparator: "'" }))))) : null)
                    : null
                : null)))));
};
export default BalanceListItem;
