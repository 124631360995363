import React, { useState } from 'react';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, useTheme, } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { TransfersCellItem } from '../../../Custody/styling/style';
import { createStyles, makeStyles } from '@mui/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import ConfirmationDialog from '../../../../../shared/components/confirmationDialog/ConfirmationDialog';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
import { Amount } from '@shared/components/styled/amount';
import { AmountCell, DateCell, TimeCell } from '../../../../../shared/components/styled/tableCells';
import { format, parseISO } from 'date-fns';
const headCells = [
    {
        id: 'customerName',
        numeric: false,
        disablePadding: true,
        label: 'Customer',
    },
    {
        id: 'clientName',
        numeric: false,
        disablePadding: true,
        label: 'Client',
    },
    {
        id: 'currency',
        numeric: true,
        disablePadding: true,
        label: 'Currency',
    },
    {
        id: 'poolWalletBalance',
        numeric: true,
        disablePadding: true,
        label: 'Pool Balance',
    },
    {
        id: 'amountToSettle',
        numeric: true,
        disablePadding: true,
        label: 'Amount to Settle',
    },
    {
        id: 'direction',
        numeric: false,
        disablePadding: true,
        label: 'Direction',
    },
    {
        id: 'settlementCode',
        numeric: true,
        disablePadding: true,
        label: 'Code',
    },
    {
        id: 'created_at',
        numeric: true,
        disablePadding: true,
        label: 'Created',
        sortable: true,
        sortId: 'created_at',
    },
    {
        id: 'failedReason',
        numeric: false,
        disablePadding: true,
        label: 'Failed Reason',
    },
];
const useStyles = makeStyles(() => createStyles({
    customTableRow: {
        '& > tr:last-of-type > td': {
            borderBottom: 0,
        },
    },
}));
const SettlementsTableBody = ({ rows, type, executeSettlement, declineSettlement, onRefreshBalance, onRequestSort, order, orderBy, onRowSelect, }) => {
    const [selecedSettlement, setSelectedSettlement] = useState('');
    const theme = useTheme();
    const classes = useStyles();
    const [confirmDialog, setConfirmDialog] = useState('');
    const [reasonDialogText, setReasonDialogText] = useState('');
    const onConfirm = (code, dialogName) => {
        setSelectedSettlement(code);
        setConfirmDialog(dialogName);
    };
    const onConfirmClose = () => {
        setSelectedSettlement('');
        setConfirmDialog('');
    };
    const onConfirmExecute = () => {
        if (executeSettlement) {
            executeSettlement(selecedSettlement);
        }
        setConfirmDialog('');
        setSelectedSettlement('');
    };
    const onConfirmDecline = () => {
        if (declineSettlement) {
            declineSettlement(selecedSettlement);
        }
        setConfirmDialog('');
        setSelectedSettlement('');
    };
    const handleShowReason = (reason) => {
        if (reason) {
            setReasonDialogText(reason);
        }
    };
    const createSortHandler = (property) => () => {
        const isAsc = orderBy === property && order === 'asc';
        if (onRequestSort) {
            onRequestSort(isAsc ? 'desc' : 'asc', property);
        }
    };
    const handleRefreshBalance = (row) => {
        if (onRefreshBalance && row.poolWalletCode) {
            onRefreshBalance(row.poolWalletCode);
        }
    };
    const handleSelectRow = (row, checked) => {
        if (onRowSelect) {
            onRowSelect(row, checked);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { sx: { minWidth: 750 }, "aria-labelledby": 'tableTitle', size: 'small' },
            React.createElement(TableHead, { sx: {
                    cursor: 'pointer',
                    backgroundColor: type !== 'created' ? `${theme.palette.secondary.light}` : 'inherit',
                } },
                React.createElement(TableRow, null,
                    headCells.map((headCell) => (React.createElement(React.Fragment, { key: headCell.id }, (type !== 'failed' && headCell.id === 'failedReason') ||
                        (type === 'created' && headCell.id === 'customerName') ||
                        (type === 'created' && headCell.id === 'clientName') ||
                        (type !== 'created' && headCell.id === 'poolWalletBalance') ? null : (React.createElement(TableCell, { sx: {
                            whiteSpace: 'nowrap',
                            color: type === 'created' ? `${theme.palette.secondary.light}` : 'inherit',
                        } }, headCell.sortable && headCell.sortId && type !== 'created' ? (React.createElement(TableSortLabel, { active: orderBy === headCell.id, direction: orderBy === headCell.id ? order : 'asc', onClick: createSortHandler(headCell.sortId) },
                        headCell.label,
                        orderBy === headCell.id ? (React.createElement(Box, { component: 'span', sx: visuallyHidden }, order === 'desc' ? 'sorted descending' : 'sorted ascending')) : null)) : (headCell.label)))))),
                    type === 'created' ? (React.createElement(TableCell, { align: 'right', sx: {
                            whiteSpace: 'nowrap',
                            color: type === 'created' ? `${theme.palette.secondary.light}` : 'inherit',
                        } }, "Actions")) : null)),
            React.createElement(TableBody, { className: classes.customTableRow }, rows.map((row, index) => {
                const labelId = `settlement-${index}`;
                return (React.createElement(React.Fragment, { key: row.settlementCode },
                    React.createElement(TableRow, { hover: true, tabIndex: -1, key: labelId, sx: { backgroundColor: type !== 'created' ? theme.palette.secondary.light : 'inherit' } },
                        type === 'created' ? null : (React.createElement(TableCell, { align: 'left' },
                            React.createElement(Box, { display: 'flex' },
                                React.createElement(TransfersCellItem, null, row.customerName)))),
                        type === 'created' ? null : (React.createElement(TableCell, { align: 'left' },
                            React.createElement(Box, { display: 'flex' },
                                React.createElement(TransfersCellItem, null, row.clientName)))),
                        React.createElement(TableCell, { align: 'left', sx: { display: 'flex', alignItems: 'center' } },
                            type !== 'created' ? null : (React.createElement(Checkbox, { size: 'small', value: row.selected, checked: row.selected, onChange: (e, checked) => handleSelectRow(row.settlementCode, checked) })),
                            React.createElement(TransfersCellItem, null, row.currency.toUpperCase())),
                        type !== 'created' ? null : (React.createElement(TableCell, { align: 'left' },
                            React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' } },
                                React.createElement(IconButton, { disabled: row.poolWalletBalance === 'N/A', size: 'small', sx: { mr: 1 }, onClick: () => handleRefreshBalance(row), "aria-label": 'Refresh balance' },
                                    React.createElement(RefreshIcon, { fontSize: 'small' })),
                                React.createElement(AmountCell, null, row.poolWalletBalance)))),
                        React.createElement(TableCell, { align: 'left' },
                            React.createElement(AmountCell, null, row.amountToSettle)),
                        React.createElement(TableCell, { align: 'left' },
                            React.createElement(TransfersCellItem, null, row.direction)),
                        React.createElement(TableCell, { align: 'left' },
                            React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' } },
                                React.createElement(AmountCell, null, row.settlementCode),
                                React.createElement(CopyValue, { value: row.settlementCode }))),
                        React.createElement(TableCell, { align: 'left' }, row.created_at || row.created_at !== undefined ? (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
                            React.createElement(DateCell, null, format(parseISO(row.created_at), 'dd-MM-yyyy')),
                            React.createElement(TimeCell, null, format(parseISO(row.created_at), 'HH:mm:ss')))) : ('N/A')),
                        type === 'failed' ? (React.createElement(TableCell, { align: 'left' },
                            React.createElement(TransfersCellItem, null, row.failedReason))) : null,
                        type === 'created' ? (React.createElement(TableCell, { align: 'right' },
                            React.createElement(Box, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 1 },
                                React.createElement(Button, { size: 'small', variant: 'outlined', onClick: () => onConfirm(row.settlementCode, 'execute') }, "Execute"),
                                React.createElement(Button, { size: 'small', variant: 'outlined', color: 'error', onClick: () => onConfirm(row.settlementCode, 'decline') }, "Decline")))) : null),
                    type !== 'created' && row.commands.records.length ? (React.createElement(TableRow, null,
                        React.createElement(TableCell, { colSpan: 8, sx: { padding: 0, borderBottom: 'unset' } },
                            React.createElement(Box, { sx: { px: 3, pb: 3 } },
                                React.createElement(Box, { sx: { borderRadius: 1, backgroundColor: theme.palette.background.paper } },
                                    React.createElement(Table, { sx: { tableLayout: 'fixed' } },
                                        React.createElement(TableHead, null,
                                            React.createElement(TableRow, null,
                                                React.createElement(TableCell, { sx: { color: theme.palette.secondary.light } }, "Command code"),
                                                React.createElement(TableCell, { sx: { color: theme.palette.secondary.light } }, "Command type"),
                                                row.status === 'created' ? React.createElement(TableCell, null, "Pool Wallet") : null,
                                                React.createElement(TableCell, { sx: { color: theme.palette.secondary.light } }, "Amount"),
                                                React.createElement(TableCell, { sx: { color: theme.palette.secondary.light } }, "State"),
                                                row.status === 'failed' ? (React.createElement(TableCell, { sx: { color: theme.palette.secondary.light } }, "Failed Reason")) : null)),
                                        React.createElement(TableBody, { className: classes.customTableRow }, row.commands.records.map((command) => (React.createElement(TableRow, { key: command.code },
                                            React.createElement(TableCell, null, command.code),
                                            React.createElement(TableCell, null, command.type),
                                            row.status === 'created' ? (React.createElement(TableCell, null,
                                                React.createElement(Amount, null, row.poolWalletBalance))) : null,
                                            React.createElement(TableCell, null,
                                                React.createElement(Amount, null, command.amount)),
                                            React.createElement(TableCell, null, command.state),
                                            row.status === 'failed' ? (React.createElement(TableCell, null,
                                                React.createElement(Button, { disabled: !command.failed_reason, onClick: () => handleShowReason(command.failed_reason) }, "Show Error"))) : null)))))))))) : null));
            }))),
        React.createElement(ConfirmationDialog, { title: 'Execute Settlement', content: `Are you sure to exucute settlement with code: ${selecedSettlement}?`, open: confirmDialog === 'execute', onClose: onConfirmClose, onConfirm: onConfirmExecute }),
        React.createElement(ConfirmationDialog, { title: 'Decline Settlement', content: `Are you sure to decline settlement with code: ${selecedSettlement}?`, open: confirmDialog === 'decline', onClose: onConfirmClose, onConfirm: onConfirmDecline }),
        React.createElement(Dialog, { open: !!reasonDialogText, onClose: () => setReasonDialogText(''), maxWidth: 'md', fullWidth: true },
            React.createElement(DialogTitle, null, "Failed Reason"),
            React.createElement(DialogContent, null,
                React.createElement(Box, { py: 1 }, reasonDialogText)),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => setReasonDialogText(''), color: 'info' }, "Ok")))));
};
export default SettlementsTableBody;
