/* eslint-disable camelcase, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call, no-useless-escape,
@typescript-eslint/ban-ts-comment, no-nested-ternary, @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import shortid from 'shortid';
import Decimal from 'decimal.js';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { Autocomplete, Box, Button, Container, Switch, TextField, useTheme } from '@mui/material';
import MarketModal from './MarketModal';
import Subscriber from '../../../../../shared/helpers/subscriber';
import RfqOrderBook from './RfqOrderBook';
import { Amount } from '../../../../../shared/components/styled/amount';
import { NumericFormat } from 'react-number-format';
import CurrencyIcon from '@shared/components/currencyIcon/CurrencyIcon';
function groupByPairs(array, keyFn) {
    return array.reduce((result, item) => {
        const key = keyFn(item);
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(item);
        return result;
    }, {});
}
function getOrderBook(data, channel) {
    const { payload } = data;
    // Narrow down to OrderBookData type or return undefined
    if (payload.bids) {
        const hitBid = payload.bids[0];
        const liftAsk = payload.asks[0];
        const orderBook = {
            hitBid: hitBid.price,
            liftAsk: liftAsk.price,
            totalBid: hitBid.volume,
            totalAsk: liftAsk.volume,
            timestamp: payload.timestamp.toString(),
        };
        if (channel) {
            return {
                [channel]: Object.assign({}, orderBook),
            };
        }
        return orderBook;
    }
    return undefined;
}
function getReverseOrderBook(data, channel) {
    const { payload } = data;
    // Narrow down to OrderBookData type or return undefined
    if (payload.bids) {
        const hitBid = payload.bids[0];
        const liftAsk = payload.asks[0];
        const orderBook = {
            hitBid: hitBid.volume,
            liftAsk: liftAsk.volume,
            totalBid: hitBid.quote_amount,
            totalAsk: liftAsk.quote_amount,
            timestamp: payload.timestamp.toString(),
        };
        if (channel) {
            return {
                [channel]: Object.assign({}, orderBook),
            };
        }
        return orderBook;
    }
    return undefined;
}
const RfQTrading = ({ customersOptions, customerCode, rfqSettings, isReverseDisabled }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const prevChannel = useRef('');
    const theme = useTheme();
    const refId = useRef(shortid.generate());
    const [channel, setChannel] = useState();
    const [availablePairs, setAvailablePairs] = useState();
    const [selectedPair, setSelectedPair] = useState();
    const [orderbook, setOrderBook] = useState({});
    const [orderBookView, setOrderBookView] = useState([]);
    const [reverseQuotation, setReverseQuotation] = useState(false);
    const [actionType, setActionType] = useState(null);
    const [currentSpread, setCurrentSpread] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [referenceId, setReferenceId] = useState('');
    const [amount, setAmount] = useState('');
    const inputPattern = /^(\d*\.|\,)?\d+$/gim;
    useEffect(() => {
        const { OrderBookWS } = window;
        if (channel) {
            const wsDataCallback = (data) => {
                var _a;
                if (!data)
                    return;
                let orderbookUpdate;
                if (reverseQuotation) {
                    orderbookUpdate = getReverseOrderBook(data);
                }
                else {
                    orderbookUpdate = getOrderBook(data);
                }
                const total = orderbookUpdate === null || orderbookUpdate === void 0 ? void 0 : orderbookUpdate.totalAsk;
                const availableAmounts = (_a = availablePairs === null || availablePairs === void 0 ? void 0 : availablePairs.find((pair) => (selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.base) === pair.base && selectedPair.quote === pair.quote)) === null || _a === void 0 ? void 0 : _a.amounts;
                if (availableAmounts === null || availableAmounts === void 0 ? void 0 : availableAmounts.includes(total)) {
                    const updateOderbook = Object.assign({}, orderbook);
                    orderbook[total] = orderbookUpdate;
                    setOrderBook(updateOderbook);
                }
            };
            const consolidatedOrderBook = new Subscriber(wsDataCallback, refId.current, 0);
            OrderBookWS.addSubscription(channel, consolidatedOrderBook);
            prevChannel.current = channel;
        }
        return () => {
            if (channel) {
                OrderBookWS.removeSubscription(channel, refId.current);
            }
        };
    }, [channel]);
    useEffect(() => {
        if (orderbook) {
            const orderbookUpdate = Object.values(orderbook).sort((a, b) => {
                if (+a.totalAsk > +b.totalAsk) {
                    return 1;
                }
                else if (+a.totalAsk < +b.totalAsk) {
                    return -1;
                }
                return 0;
            });
            if (!currentSpread && orderbookUpdate.length) {
                setCurrentSpread(orderbookUpdate[0].totalAsk);
                setAmount(orderbookUpdate[0].totalAsk);
            }
            setOrderBookView(orderbookUpdate);
        }
    }, [orderbook]);
    useEffect(() => {
        if (selectedPair) {
            setCurrentSpread('');
            setOrderBook({});
            setOrderBookView([]);
            setChannel(`rfq-${reverseQuotation ? 'reverse-quotation-' : ''}orderbook-${selectedPair.base}/${selectedPair.quote}`);
        }
    }, [selectedPair, reverseQuotation]);
    const onPairSelect = (pair) => {
        setSelectedPair(pair);
    };
    useEffect(() => {
        if (rfqSettings.length) {
            const grouped = groupByPairs(rfqSettings, (r) => `${r.base}/${r.quote}`);
            const availablePairs = Object.keys(grouped).map((p) => {
                let amounts;
                if (reverseQuotation) {
                    amounts = grouped[p].filter((group) => group.stream_type !== 'standard');
                }
                else {
                    amounts = grouped[p].filter((group) => group.stream_type === 'standard');
                }
                return {
                    base: p.split('/')[0],
                    quote: p.split('/')[1],
                    id: shortid.generate(),
                    label: `${p.toUpperCase().split('/')[0]} / ${p.toUpperCase().split('/')[1]}`,
                    amounts: amounts.map((i) => i.guaranteed_qty),
                };
            });
            setSelectedPair(availablePairs[0]);
            setAvailablePairs(availablePairs);
        }
    }, [rfqSettings, reverseQuotation]);
    const onSpreadSelect = (a) => {
        setCurrentSpread(a);
        setAmount(a);
    };
    const handleAmountUpdate = (event) => {
        const val = event.target.value.trim();
        val.replace(/^0+/, '0');
        if (val && !inputPattern.exec(val)) {
            event.preventDefault();
            return;
        }
        setAmount(val);
    };
    return (React.createElement(Box, { p: 2 },
        React.createElement(Container, { maxWidth: 'md' },
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 2 } },
                React.createElement(Box, { sx: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2 } },
                    React.createElement(Box, { sx: { display: 'flex', gap: 2, py: 2 } },
                        (selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.base) && React.createElement(CurrencyIcon, { code: selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.base }),
                        availablePairs ? (React.createElement(Autocomplete, { sx: { minWidth: '200px' }, value: selectedPair, options: availablePairs, disableClearable: true, onChange: (e, value) => onPairSelect(value), isOptionEqualToValue: (option, selected) => option.id === selected.id, renderOption: (props, option) => (React.createElement(Box, Object.assign({ component: 'li' }, props, { key: option.id, sx: { display: 'flex', justifyContent: 'space-between', width: '100%' } }),
                                React.createElement(Chip, { label: option === null || option === void 0 ? void 0 : option.label }))), renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: 'Trading pair', variant: 'outlined', size: 'small' }))) })) : null,
                        (selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.quote) && React.createElement(CurrencyIcon, { code: selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.quote })),
                    React.createElement(Box, null,
                        React.createElement(Box, null,
                            "Normal",
                            React.createElement(Switch, { disabled: isReverseDisabled, checked: reverseQuotation, onChange: () => setReverseQuotation(!reverseQuotation) }),
                            "Reverse")),
                    !customerCode ? (React.createElement(Box, { sx: { minWidth: '200px' } },
                        React.createElement(Autocomplete, { value: selectedCustomer, options: customersOptions, renderOption: (props, option) => (React.createElement(Box, Object.assign({ component: 'li' }, props, { key: option.value }), option.label)), onChange: (e, value) => setSelectedCustomer(value), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: 'Customer', variant: 'outlined', size: 'small' })) }))) : null),
                React.createElement(Divider, null),
                React.createElement(Box, null,
                    React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'start', gap: 1 } },
                        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'start', width: '100%' } },
                            React.createElement(Button, { variant: 'contained', color: 'error', fullWidth: true, sx: { maxWidth: '160px' }, onClick: () => setActionType('Sell'), disabled: !((_a = orderbook[currentSpread]) === null || _a === void 0 ? void 0 : _a.hitBid) },
                                React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
                                    React.createElement(Box, null, "Sell"),
                                    React.createElement(Box, null,
                                        React.createElement(Amount, { size: '12' },
                                            React.createElement(NumericFormat, { displayType: 'text', value: (_b = orderbook[currentSpread]) === null || _b === void 0 ? void 0 : _b.hitBid, thousandSeparator: "'" })))))),
                        React.createElement(TextField, { sx: { minWidth: '200px' }, size: 'small', value: amount, type: 'number', error: !amount.match(inputPattern), label: (_c = (reverseQuotation ? selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.quote : selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.base)) === null || _c === void 0 ? void 0 : _c.toUpperCase(), onChange: (e) => {
                                handleAmountUpdate(e);
                            }, inputProps: { min: 0, step: (selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.base) === 'btc' ? 0.001 : 1 }, helperText: new Decimal(((_d = orderbook[currentSpread]) === null || _d === void 0 ? void 0 : _d.hitBid) || 0)
                                .add(((_e = orderbook[currentSpread]) === null || _e === void 0 ? void 0 : _e.liftAsk) || 0)
                                .div(2)
                                .mul(amount || 0)
                                .toFixed() + ` ${(_f = (reverseQuotation ? selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.base : selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.quote)) === null || _f === void 0 ? void 0 : _f.toUpperCase()}` }),
                        React.createElement(TextField, { size: 'small', sx: { minWidth: '200px' }, value: referenceId, label: 'Reference Id', onChange: (e) => {
                                setReferenceId(e.target.value);
                            } }),
                        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'end', width: '100%' } },
                            React.createElement(Button, { variant: 'contained', color: 'success', onClick: () => setActionType('Buy'), fullWidth: true, disabled: !((_g = orderbook[currentSpread]) === null || _g === void 0 ? void 0 : _g.liftAsk), sx: { maxWidth: '160px' } },
                                React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
                                    React.createElement(Box, null, "Buy"),
                                    React.createElement(Box, null,
                                        React.createElement(Amount, { size: '12' },
                                            React.createElement(NumericFormat, { displayType: 'text', value: ((_h = orderbook[currentSpread]) === null || _h === void 0 ? void 0 : _h.liftAsk) || 0, thousandSeparator: "'" })))))))),
                React.createElement(RfqOrderBook, { orderbook: orderBookView, onSelect: onSpreadSelect, quantity: currentSpread }))),
        actionType && selectedPair ? (React.createElement(MarketModal, { base: selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.base, quote: selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.quote, action: actionType, orderAmt: amount, bidUpdate: orderbook[currentSpread].hitBid || '', askUpdate: orderbook[currentSpread].liftAsk || '', timestamp: orderbook[currentSpread].timestamp || '', streamType: reverseQuotation ? 'reverse' : 'standard', open: !!actionType, customerCode: (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.value) || customerCode || null, reference_id: referenceId, toggleFunc: () => setActionType(null), guaranteed: currentSpread })) : null));
};
export default RfQTrading;
