import { Box, useTheme } from '@mui/material';
import React from 'react';
const CurrencyIcon = (props) => {
    const theme = useTheme();
    return (React.createElement(Box, { sx: {
            backgroundColor: theme.palette.background.paper,
            width: '36px',
            height: '36px',
            borderRadius: '50%',
        } }));
};
export default CurrencyIcon;
