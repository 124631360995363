var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Autocomplete, Box, Button, Checkbox, Container, Dialog, FormControlLabel, FormGroup, LinearProgress, Tab, TablePagination, Tabs, TextField, useTheme, } from '@mui/material';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import Messages from '../../../../../../shared/helpers/errorMessages';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import showNotification from '../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import InitiateSettlements from './../InitiateSettlements';
import ClientSettlementsTableBody from './ClientSettlementsBody';
import { getComparator, stableSort } from '../../../../../../shared/helpers/tables';
import { ASSETS } from './assets';
import { SET_SELECTED_CLIENT } from '../../../../../../redux/actionTypes/clientSearchActionTypes';
import DeclineAllSetllments from './DeclineAllSettlements';
import ConfirmationDialog from '@shared/components/confirmationDialog/ConfirmationDialog';
import { getCustodyCurrencies } from '../../../../../../redux/selectors/currenciesSelectors';
const selectClientUpdate = (selectedClient) => ({
    type: SET_SELECTED_CLIENT,
    selectedClient,
});
function mapSettlements(settlements, customers) {
    return settlements.map((item) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return {
            clientCode: item.client_code,
            settlementCode: item.settlement_code,
            customer: customers === null || customers === void 0 ? void 0 : customers.find((c) => c.code === item.customer_code),
            currency: item.currency_code,
            status: item.state,
            failedReason: item.failed_reason || '',
            commands: item.commands,
            collapsed: false,
            dateFrom: (_c = (_b = (_a = item.data) === null || _a === void 0 ? void 0 : _a.records) === null || _b === void 0 ? void 0 : _b.find((r) => r.type === 'date_from')) === null || _c === void 0 ? void 0 : _c.value,
            dateTo: (_f = (_e = (_d = item.data) === null || _d === void 0 ? void 0 : _d.records) === null || _e === void 0 ? void 0 : _e.find((r) => r.type === 'date_to')) === null || _f === void 0 ? void 0 : _f.value,
            created_at: item.created_at,
            poolWalletBalance: item.wallet === undefined ? 'N/A' : item.wallet.balance,
            poolWalletCode: (_g = item.wallet) === null || _g === void 0 ? void 0 : _g.code,
            settlement_address_uuid: (_k = (_j = (_h = item.data) === null || _h === void 0 ? void 0 : _h.records) === null || _j === void 0 ? void 0 : _j.find((r) => r.type === 'settlement_address_uuid')) === null || _k === void 0 ? void 0 : _k.value,
            data: item.data ? item.data : { records: [] },
            selected: false,
        };
    });
}
const ClientSettlementsTable = (props) => {
    const [initiateDialog, setInitiateDialog] = useState(false);
    const [declineDialog, setDeclineDialog] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [viewSettlements, setViewSettlements] = useState([]);
    const [settlements, setSettlements] = useState([]);
    const [statusFilter, setStatusFilter] = useState('created');
    const [loading, setLoading] = useState(true);
    const [confirmExecute, setConfirmExecute] = useState(false);
    const [currency, setCurrency] = useState(null);
    const [state, setState] = useState({
        order: 'desc',
        orderBy: 'created_at',
        rowsPerPage: 10,
        page: 0,
    });
    const theme = useTheme();
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        return yield axios
            .get('settlements/accounts', {
            baseURL: getEnvSettings().adminApiUrl,
            params: {
                states: statusFilter,
                limit: statusFilter !== 'created' ? state.rowsPerPage : null,
                page: statusFilter !== 'created' ? state.page + 1 : null,
                sort_by: state.orderBy === 'created_at' ? state.orderBy : null,
                sort_direction: state.order,
                type: props.type,
                client_code: props.clientSettlementId,
                currency: currency === null || currency === void 0 ? void 0 : currency.ticker.toLowerCase(),
            },
        })
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            setTotalItems(+response.data.total || 0);
            if (statusFilter === 'created') {
                for (let i = 0; response.data.records.length > i; i++) {
                    yield fetchWallets(response.data.records[i].currency_code).then((wallet) => {
                        var _a, _b;
                        if (wallet) {
                            const balance = (_b = (_a = wallet === null || wallet === void 0 ? void 0 : wallet.balance) === null || _a === void 0 ? void 0 : _a.available) !== null && _b !== void 0 ? _b : '0';
                            const walletRecord = !wallet ? undefined : { balance, code: wallet.code };
                            response.data.records[i].wallet = walletRecord;
                        }
                    });
                }
                return response.data.records;
            }
            else {
                return response.data.records;
            }
        }))
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    });
    const executeSettlement = (id) => {
        return axios
            .post(`/settlements/accounts/${id}/execute/`, null, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_EXECUTE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const declineSettlement = (id) => {
        return axios
            .post(`/settlements/accounts/${id}/decline/`, null, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_DECLINE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const manualExecute = (params) => {
        return axios
            .post(`/settlements/accounts/${params.settlementCode}/command/${params.commandCode}/manually_execute`, { reference_id: params.reference }, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_DECLINE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const fetchWallets = (currency_code) => __awaiter(void 0, void 0, void 0, function* () {
        const internalCode = ASSETS[currency_code.toUpperCase()];
        const currency = internalCode !== undefined ? internalCode : currency_code;
        return yield axios
            .get(`/custody/${props.clientId}/wallets?limit=1000&state=all&currency_code=${currency}`)
            .then((res) => {
            const result = res.data.records.find((d) => d.label.toUpperCase().includes('POOL'));
            return result;
        });
    });
    const refreshBalance = (code) => {
        return axios
            .post(`/custody/wallets/${code}/balance`, null, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .then(() => {
            showNotification({
                message: `Balance has been successfully updated`,
                color: 'success',
            });
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, 'Failed to refresh the balance');
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const onManualExecute = (params) => __awaiter(void 0, void 0, void 0, function* () {
        yield manualExecute(params).finally(() => {
            getData();
        });
        showNotification({
            message: `Settlement was executed`,
            color: 'success',
            dispatch: errorNotice,
        });
    });
    const onExecuteSettlement = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield executeSettlement(id).finally(() => {
            getData();
        });
        showNotification({
            message: `Settlement was executed`,
            color: 'success',
            dispatch: errorNotice,
        });
    });
    const onDeclineSettlement = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield declineSettlement(id).finally(() => {
            getData();
        });
        showNotification({
            message: `Settlement was declined`,
            color: 'success',
            dispatch: errorNotice,
        });
    });
    const getData = () => __awaiter(void 0, void 0, void 0, function* () {
        let settlements = [];
        setLoading(true);
        yield fetchData().then((res) => {
            if (res) {
                settlements = res;
            }
            setSettlements(settlements);
        });
        setLoading(false);
        return { settlements };
    });
    useEffect(() => {
        props.selectClientUpdateDispatch(props.clientSettlementId || null);
        getData();
    }, [statusFilter, state.page, state.rowsPerPage, currency]);
    useEffect(() => {
        setViewSettlements(mapSettlements(settlements, props.customers));
    }, [props.customers, settlements]);
    useEffect(() => {
        if (statusFilter !== 'created' && state.orderBy === 'created_at') {
            getData();
        }
    }, [state.order, state.orderBy]);
    useEffect(() => {
        return () => {
            props.selectClientUpdateDispatch(null);
        };
    }, []);
    const handleChangePage = (event, newPage) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { page: newPage })));
    };
    const handleChangeRowsPerPage = (event) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { page: 0, rowsPerPage: parseInt(event.target.value, 10) })));
    };
    const onFilterChange = (filter) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { page: 0, order: 'desc' })));
        setStatusFilter(filter);
    };
    const handleToggleDialog = (result) => {
        setInitiateDialog(false);
        if (result) {
            getData();
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { page: 0 })));
            setStatusFilter('created');
        }
    };
    const handleSort = (order, orderBy) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { order,
            orderBy })));
    };
    const handleRefreshBalance = (walletCode) => {
        refreshBalance(walletCode);
    };
    const handleRowSelect = (id, selected) => {
        setViewSettlements((prev) => prev.map((settlement) => {
            if (settlement.settlementCode === id) {
                return Object.assign(Object.assign({}, settlement), { selected });
            }
            return settlement;
        }));
    };
    const handleSelectAll = (checked) => {
        setViewSettlements((prev) => prev.map((settlement) => {
            return Object.assign(Object.assign({}, settlement), { selected: checked });
        }));
    };
    const executeAll = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        if (ids.length) {
            let n = 0;
            while (n < ids.length) {
                const id = ids[n];
                yield new Promise((resolve) => setTimeout(() => {
                    resolve(id);
                }, 1000));
                yield executeSettlement(id);
                n += 1;
            }
        }
    });
    const handleExecuteAll = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const settlementsToExecute = viewSettlements.filter((s) => s.selected).map((s) => s.settlementCode);
        yield executeAll(settlementsToExecute).finally(() => {
            showNotification({
                message: `Settlements were successfully executed`,
                color: 'success',
            });
            getData();
        });
        setLoading(false);
        setConfirmExecute(false);
    });
    const handleCurrencyChange = (value) => {
        setCurrency(value);
    };
    const visibleRows = React.useMemo(() => {
        return stableSort(viewSettlements, getComparator(state.order, state.orderBy));
    }, [viewSettlements, state.order, state.orderBy]);
    return (React.createElement(Container, { maxWidth: 'xl' },
        React.createElement(Box, null,
            React.createElement(Box, { height: '5px' }, loading && React.createElement(LinearProgress, null)),
            React.createElement(Box, { mb: 2, sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottom: `2px solid ${theme.palette.background.default}`,
                } },
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
                    React.createElement(Tabs, { value: statusFilter, onChange: (e, value) => onFilterChange(value), "aria-label": 'basic tabs example' },
                        React.createElement(Tab, { label: 'Created', value: 'created', disabled: loading }),
                        React.createElement(Tab, { label: 'Pending', value: 'pending', disabled: loading }),
                        React.createElement(Tab, { label: 'Failed', value: 'failed', disabled: loading }),
                        React.createElement(Tab, { label: 'Declined', value: 'declined', disabled: loading }),
                        React.createElement(Tab, { label: 'Success', value: 'success', disabled: loading })),
                    React.createElement(Box, { sx: { display: 'flex', gap: 1 } },
                        React.createElement(Button, { size: 'small', variant: 'outlined', onClick: () => getData() }, "Refresh"),
                        React.createElement(Button, { size: 'small', variant: 'contained', onClick: () => setInitiateDialog(true), disabled: viewSettlements.length > 0 }, "Initiate"),
                        React.createElement(Button, { size: 'small', variant: 'contained', color: 'error', onClick: () => setDeclineDialog(true) }, "Decline all"))),
                statusFilter !== 'created' ? null : (React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 } },
                    React.createElement(Autocomplete, { sx: { width: '25ch' }, disabled: loading, options: props.currencies, value: currency, size: 'small', getOptionLabel: (option) => option.label, onChange: (event, newValue) => handleCurrencyChange(newValue), renderInput: (params) => (React.createElement(TextField, Object.assign({ name: 'currency_code' }, params, { label: 'Currency', variant: 'outlined', value: {
                                value: currency,
                                label: 'Currency',
                            }, size: 'small' }))) }),
                    React.createElement(Box, { sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 1,
                            justifyContent: 'flex-end',
                        } },
                        React.createElement(FormGroup, { sx: { display: 'flex', justifyContent: 'space-between' } },
                            React.createElement(FormControlLabel, { label: 'Select ALL', control: React.createElement(Checkbox, { size: 'small', disabled: loading || viewSettlements.length === 0, onClick: (e) => e.stopPropagation(), indeterminate: viewSettlements.some((s) => s.selected) && !viewSettlements.every((s) => s.selected), checked: viewSettlements.every((s) => s.selected), value: viewSettlements.every((s) => s.selected), onChange: (e, checked) => handleSelectAll(checked) }) })),
                        React.createElement(Button, { size: 'small', variant: 'contained', color: 'primary', onClick: () => setConfirmExecute(true), disabled: loading || viewSettlements.some((s) => s.selected) === false }, "Execute selected")))),
                React.createElement(ConfirmationDialog, { title: 'Execute Settlements', content: `Are you sure to exucute selected settlements?`, open: confirmExecute, onClose: () => setConfirmExecute(false), onConfirm: handleExecuteAll, loading: loading }),
                React.createElement(Dialog, { open: initiateDialog, onClose: () => setInitiateDialog(false), fullWidth: true },
                    React.createElement(InitiateSettlements, { toggleDialog: (e) => handleToggleDialog(e), clientSettlementId: props.clientSettlementId, withDates: true, settlementType: props.type })),
                declineDialog && (React.createElement(DeclineAllSetllments, { type: props.type, open: declineDialog, clientId: props.clientSettlementId, clientsOptions: props.clients.map((c) => ({ label: c.company_name, value: c.code })), customersOptions: props.customers.map((c) => ({ label: c.company_name || c.code, value: c.code })), onClose: () => {
                        setDeclineDialog(false);
                        getData();
                    } })))),
        loading ? null : (React.createElement(Box, null,
            React.createElement(React.Fragment, null, viewSettlements.length ? (React.createElement(Box, { sx: { overflow: 'auto' } },
                React.createElement(ClientSettlementsTableBody, { withCustomers: props.type !== 'etoro', rows: visibleRows, type: statusFilter, onRequestSort: handleSort, order: state.order, orderBy: state.orderBy, manualExecute: onManualExecute, executeSettlement: onExecuteSettlement, declineSettlement: onDeclineSettlement, onRefreshBalance: handleRefreshBalance, onSelectRow: handleRowSelect }))) : (React.createElement(Alert, { severity: 'info' }, "No settlements available"))),
            statusFilter !== 'created' && (React.createElement(TablePagination, { rowsPerPageOptions: [5, 10, 25, 1000], component: 'div', count: totalItems, rowsPerPage: state.rowsPerPage === 'all' ? totalItems : state.rowsPerPage, page: state.page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage }))))));
};
const stateToProps = (state) => {
    var _a;
    const { selectedClient, allClients, loading } = state.clientSearch;
    const { customers, loaded } = state.customersSearch;
    const clientId = (_a = state.client.clientInfo) === null || _a === void 0 ? void 0 : _a.code;
    const custodyCurrencies = getCustodyCurrencies(state);
    return {
        selectedClient,
        clientId,
        customers,
        customersLoaded: loaded,
        clients: allClients,
        clientsLoading: loading,
        currencies: custodyCurrencies,
    };
};
const mapDispatchToProps = (dispatch) => ({
    selectClientUpdateDispatch: (client) => dispatch(selectClientUpdate(client)),
});
export default connect(stateToProps, mapDispatchToProps)(ClientSettlementsTable);
