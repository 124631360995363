import React, { useState, useEffect } from 'react';
import NoteIcon from '@mui/icons-material/Note';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Popover, Chip, Box } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';
import { TransfersCellItem } from '../../styling/style';
import { statusBadgeData } from '../../../../../config/statusBadgeData';
import statusBadgeColor from '../../../../../shared/helpers/statusBadgeColor';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
import CurrencyIcon from '@shared/components/currencyIcon/CurrencyIcon';
const WalletNote = ({ note }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    if (note === undefined) {
        return null;
    }
    return (React.createElement(TableCell, { align: "right" },
        React.createElement(IconButton, { onClick: handleClick, "aria-label": "Wallet note", className: "transactions_button_style", size: "large" },
            React.createElement(NoteIcon, { className: "transactions_wallet_note_fs" })),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: { vertical: 'top', horizontal: 'center' }, transformOrigin: { vertical: 'bottom', horizontal: 'center' } },
            React.createElement(Typography, { className: "wallets_note_typography" }, note))));
};
const WalletTableCell = ({ value, columnKey, wallet }) => {
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(0);
    useEffect(() => {
        if (width) {
            setCellWidth(width - 20);
        }
    }, [width]);
    if (columnKey === 'currency_code') {
        return (React.createElement(TableCell, { align: "left" },
            React.createElement(Box, { display: 'flex', alignItems: "center", gap: 1 },
                value && React.createElement(CurrencyIcon, { code: value }),
                value ? `${value}`.toUpperCase() : 'n/a',
                wallet.is_migrated ? (React.createElement(CopyValue, { value: value })) : null)));
    }
    if (columnKey === 'deposit_address') {
        return (React.createElement(TableCell, { align: "left", ref: ref },
            value ? (React.createElement(CopyValue, { value: value })) : null,
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value)));
    }
    if (columnKey === 'created_at') {
        return (React.createElement(TableCell, { align: "left" }, value ? `${new Date(`${value}`).toLocaleDateString()}, ${new Date(`${value}`).toLocaleTimeString()}` : 'n/a'));
    }
    if (columnKey === 'label') {
        return (React.createElement(TableCell, { align: "left", ref: ref },
            value ? (React.createElement(CopyValue, { value: value })) : null,
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value || 'N/A')));
    }
    if (columnKey === 'customer_code') {
        return (React.createElement(TableCell, { align: "left", ref: ref }, value === 'n/a' ? null : (React.createElement(Chip, { color: "info", label: value, size: "small" }))));
    }
    if (columnKey === 'note') {
        return React.createElement(WalletNote, { note: value });
    }
    if (columnKey === 'state') {
        return (React.createElement(TableCell, { align: "left" },
            React.createElement(Chip, { size: "small", label: value || 'N/A', style: statusBadgeColor(statusBadgeData
                    .stateBackgroundsWallets[(value || '').replace(/-/g, '_')], statusBadgeData
                    .stateBordersWallets[(value || '').replace(/-/g, '_')], statusBadgeData
                    .stateColorsWallets[(value || '').replace(/-/g, '_')]).badge })));
    }
    return (React.createElement(TableCell, { align: "left" }, value || 'N/A'));
};
export default WalletTableCell;
