import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
const ConfirmationDialog = ({ open, onClose, onConfirm, title, content, loading }) => {
    return (React.createElement(Dialog, { open: open, onClose: onClose, maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, null, title),
        React.createElement(DialogContent, null, content),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, color: "info", disabled: loading }, "Cancel"),
            React.createElement(Button, { onClick: onConfirm, color: "primary", variant: 'contained', disabled: loading }, "Confirm"))));
};
export default ConfirmationDialog;
